import numeral from "numeral";
import * as moment from 'moment';

if(moment.locale() != "ru") {
    moment.locale('ru');
}

if(numeral.locale() != "ru"){
    numeral.register('locale', 'ru', {
        delimiters: {
            thousands: ' ',
            decimal: ' '
        },
        abbreviations: {
            thousand: 'тыс',
            million: 'млн',
            billion: 'млрд',
            trillion: 'трлн'
        },
        currency: {
            symbol: '₽'
        }
    });
    numeral.locale("ru");
}

const Palette = [
    "#677E9B",
    "#707070",
    "#679B94",
    "#CE7A41",
];

export default class Tools {

    static compose(...fns) {
        return fns.reduceRight((prevFn, nextFn) =>
                (...args) => nextFn(prevFn(...args)),
            value => value);
    }

    static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
    }

    static isEmpty(array){
        return !array || array.length === 0;
    }

    static isWhitespace(str){
        return !str || str == "" || str == " " || str == '\t';
    }

    static toUpperCaseFirstChar(str) {
        if (str) {
            return str[0].toUpperCase() + str.substring(1)
        }
        return str;
    }

    static toLowerCaseFirstChar(str) {
        if (str) {
            return str[0].toLowerCase() + str.substring(1)
        }
        return str;
    }

    static toCanonicalString(str){
        if(!str){
            return "";
        }
        return str.replace(/[^a-zA-ZА-Яа-я0-9ёЁ \.\,\!\:\;\?\-]/gm, "");
    }

    static toDate(date){
        if(!date){
            return "";
        }
        return moment(date).fromNow();
    }

    static toCurrency(value){
        if(!value){
            return "";
        }
        return numeral(value).format("0,0 $");
    }

    static toList (items, func)  {
        if(items){
            return items.map(item => {
                if(func){
                    return func(item);
                }

                return {
                    id: item.id,
                    title: item.name,
                    count: item.vacanciesCount
                };
            });
        }
        return [];
    };

    static distinct(array, provider = (e) => e){
        if(array){
            return array.reduce((result, item) => {
                if(!result.find(e => provider(e) === provider(item))){
                    result.push(item);
                }
                return result;
            }, []);
        }
    }

    static scrollTop = () => {
        if(window){
            window.scrollTo(0, 0);
        }
    };

    static palette(index) {
        return Palette[index >= Palette.length ? index % Palette.length: index];
    }

    static transliterate = function(text) {

        if(!text){
            return "";
        }
        text = text.toLowerCase()
            .replace(/\u0401/g, 'YO')
            .replace(/\u0419/g, 'I')
            .replace(/\u0426/g, 'TS')
            .replace(/\u0423/g, 'U')
            .replace(/\u041A/g, 'K')
            .replace(/\u0415/g, 'E')
            .replace(/\u041D/g, 'N')
            .replace(/\u0413/g, 'G')
            .replace(/\u0428/g, 'SH')
            .replace(/\u0429/g, 'SCH')
            .replace(/\u0417/g, 'Z')
            .replace(/\u0425/g, 'H')
            .replace(/\u042A/g, '')
            .replace(/\u0451/g, 'yo')
            .replace(/\u0439/g, 'i')
            .replace(/\u0446/g, 'ts')
            .replace(/\u0443/g, 'u')
            .replace(/\u043A/g, 'k')
            .replace(/\u0435/g, 'e')
            .replace(/\u043D/g, 'n')
            .replace(/\u0433/g, 'g')
            .replace(/\u0448/g, 'sh')
            .replace(/\u0449/g, 'sch')
            .replace(/\u0437/g, 'z')
            .replace(/\u0445/g, 'h')
            .replace(/\u044A/g, "")
            .replace(/\u0424/g, 'F')
            .replace(/\u042B/g, 'I')
            .replace(/\u0412/g, 'V')
            .replace(/\u0410/g, 'a')
            .replace(/\u041F/g, 'P')
            .replace(/\u0420/g, 'R')
            .replace(/\u041E/g, 'O')
            .replace(/\u041B/g, 'L')
            .replace(/\u0414/g, 'D')
            .replace(/\u0416/g, 'ZH')
            .replace(/\u042D/g, 'E')
            .replace(/\u0444/g, 'f')
            .replace(/\u044B/g, 'i')
            .replace(/\u0432/g, 'v')
            .replace(/\u0430/g, 'a')
            .replace(/\u043F/g, 'p')
            .replace(/\u0440/g, 'r')
            .replace(/\u043E/g, 'o')
            .replace(/\u043B/g, 'l')
            .replace(/\u0434/g, 'd')
            .replace(/\u0436/g, 'zh')
            .replace(/\u044D/g, 'e')
            .replace(/\u042F/g, 'Ya')
            .replace(/\u0427/g, 'CH')
            .replace(/\u0421/g, 'S')
            .replace(/\u041C/g, 'M')
            .replace(/\u0418/g, 'I')
            .replace(/\u0422/g, 'T')
            .replace(/\u042C/g, "")
            .replace(/\u0411/g, 'B')
            .replace(/\u042E/g, 'YU')
            .replace(/\u044F/g, 'ya')
            .replace(/\u0447/g, 'ch')
            .replace(/\u0441/g, 's')
            .replace(/\u043C/g, 'm')
            .replace(/\u0438/g, 'i')
            .replace(/\u0442/g, 't')
            .replace(/\u044C/g, "")
            .replace(/\u0431/g, 'b')
            .replace(/\u044E/g, 'yu')
            .replace(/\u0020/g, "-")
            .replace(' ', "-")
            .replace('/', "-");

        return text;
    };
}